import * as React from "react"
// import { graphql, Link } from 'gatsby';
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import Hero from '../components/psu-brand/hero';
// import HeroBespoke from '../components/psu-brand/hero-bespoke';
// import CraftImage from '../components/craftcms/craft-image';
// import HeroImageHeavy from '../components/psu-brand/hero-image-heavy';
// import { DummyImage } from "react-simple-placeholder-image"
import ContentBuilder from "../components/craftcms/content-builder"
// import ContentBuilderQuery from "../graphql-fragments/content-builder-query"

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Carousel from 'react-bootstrap/Carousel';
import "../components/scss/bootstrap-custom/components/carousel.scss"
// import Card from 'react-bootstrap/Card';
import "../components/scss/bootstrap-custom/components/card.scss"
// import Button from "react-bootstrap/Button"

const IndexPage = ({ location, data }) => {
  // const entryData = data.craftAPI.entry;
  // const heroData = entryData.hero[0];

  return (
    <Layout location={location}>
      <Seo title="Home" />

      {/* <HeroBespoke
				image={heroData.image[0]}
				heading={heroData.heading}
				subheading={heroData.subheading}
				cta={heroData.callToAction}
				ctaLink={heroData.callToActionLink}
			></HeroBespoke>

			
			<Container key={Math.random()} className="my-5">
				<Row xs={11} md={8} className="g-4">
					<Col
						key="bodytext"
						dangerouslySetInnerHTML={{ __html: entryData.bodyText }}
					></Col>
				</Row>
			</Container> */}

      <ContentBuilder {...data.craftAPI.entry} />

      {/* <Container key={Math.random()} className="my-5">
				<Row xs={1} md={3} className="g-4">
					{Array.from({ length: 3 }).map((_, idx) => (
						<Col key={idx}>
							<Card>
							
								<Card.Body>
									<Card.Title>Card title</Card.Title>
									<Card.Text>
										This is a longer card with supporting
										text below as a natural lead-in to
										additional content. This content is a
										little bit longer.
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container> */}

      {/* <Container key={Math.random()} fluid className="mt-5 pt-5 g-0">
      <Row className="g-0"> */}
      {/* <HeroImageHeavy articles={data.craftAPI.entries} /> */}
      {/* </Row>
    </Container> */}

      {/* <Container key={Math.random()} fluid className="mt-5 g-0">
				<Row className="g-0">
					<Hero />
				</Row>
			</Container> */}

      {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    craftAPI {
      entry(section: "homepage") {
        title
        id
        ... on CraftAPI_homepage_homepage_Entry {
          id
          contentBuilder {
            ...ContentBuilderQuery
          }
        }
      }
    }
  }
`
// export const query = graphql`
//   query HomePageQuery {
//     craftAPI {
//       entry(section: "homepage") {
//         title
//         id
//         ... on CraftAPI_homepage_homepage_Entry {
//           id
//           contentBuilder {
//             ...HeroBespokeBlockQuery
//           }
//         }
//       }
//     }
//   }
// `

// export const query = graphql`
//   query HomePageQuery {
//     craftAPI {
//       entry(section: "homepage") {
//         id
//         title
//         ... on CraftAPI_homepage_homepage_Entry {
//           id
//           contentBuilder {
//             ... on CraftAPI_contentBuilder_heroBespoke_BlockType {
//               id
//               heading
//               callToAction
//               callToActionLink
//               image {
//                 extension
//                 filename
//                 focalPoint
//                 hasFocalPoint
//                 path
//                 title
//                 width
//                 url
//               }
//               subheading
//               typeHandle
//             }
//           }
//           typeHandle
//         }
//       }
//     }
//   }
// `

export default IndexPage
